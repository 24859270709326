export const vehicleMakeAndModels = {
  abarth: ["750 & 850", "era1950s", "era1960s", "era1970s", "era1980s"],
  ac: ["e & eca", "era1930s", "era1960s", "era1980s"],
  acadian: ["era1960s"],
  acoma: ["era1970s"],
  acura: [
    "cl",
    "era1980s",
    "honda",
    "integra",
    "integra type r",
    "legend",
    "nsx na1",
    "nsx na1/na2",
    "nsx na2",
    "nsx nc1",
    "parts and automobilia",
    "rsx",
    "tl",
  ],
  airstream: ["era1970s", "era1980s", "era2010s", "era2020s"],
  ajs: ["era1930s", "era1940s", "era1950s", "era1970s"],
  "alfa romeo": [
    "102-series 2000",
    "105 series sedan",
    "105/115 series coupe",
    "105/115 spider series 1",
    "105/115 spider series 2",
    "105/115 spider series 3",
    "105/115 spider series 4",
    "106-series 2600",
    "164",
    "1900",
    "4c",
    "6c",
    "75/milano",
    "750/101 series giulietta & giulia",
    "8c",
    "952 series giulia",
    "alfetta",
    "gtv & spider (type 916)",
    "gtv6",
    "montreal",
    "parts and automobilia",
    "sprint speciale",
    "sz/rz",
  ],
  allard: ["era1940s", "era1950s", "era1980s", "era2010s"],
  alpine: ["a110"],
  alvis: [
    "era1920s",
    "era1930s",
    "era1950s",
    "era1960s",
    "era1970s",
    "era1980s",
    "originbritish",
  ],
  "am general": ["era1970s", "era1980s", "era1990s"],
  amc: [
    "ambassador",
    "amx",
    "eagle",
    "era1950s",
    "era1960s",
    "gremlin",
    "javelin",
    "pacer",
  ],
  "american bantam": ["era1930s", "era1940s"],
  "american lafrance": [
    "era1920s",
    "era1930s",
    "era1940s",
    "era1970s",
    "era2000s",
  ],
  amphicar: ["770", "parts and automobilia"],
  apollo: ["era1960s", "era1970s", "era1980s"],
  aprilia: ["aprilia rsv4 rf", "era1980s", "era1990s", "era2000s", "era2010s"],
  ariel: ["atom", "motorcycles"],
  arnolt: ["era1950s"],
  "aston martin": [
    "db11",
    "db2, db2/4, and db mark iii",
    "db4",
    "db5",
    "db6",
    "db7",
    "db9",
    "dbs",
    "dbx",
    "era1980s",
    "lagonda",
    "parts and automobilia",
    "rapide",
    "v12 vantage",
    "v8 (1977-1989)",
    "v8 vantage",
    "v8 vantage (2006+)",
    "vanquish",
    "virage",
  ],
  auburn: ["851/852", "era1920s", "era1930s", "replica"],
  audi: [
    "a4",
    "a5",
    "a6",
    "a8",
    "allroad",
    "b1 fox & 80",
    "b2 80, 90, & 4000",
    "b3 80 & 90",
    "b4 80 & 90",
    "c1 100",
    "c2 100, 200, & 5000",
    "c3 100, 200, & 5000",
    "coupe",
    "parts and automobilia",
    "q5",
    "q7",
    "r8",
    "rs2",
    "rs3",
    "rs4",
    "rs5",
    "rs6",
    "rs7",
    "s2",
    "s4",
    "s5",
    "s6",
    "s8",
    "tt",
    "ur-quattro",
  ],
  austin: [
    "era1920s",
    "era1930s",
    "era1940s",
    "era1950s",
    "era1960s",
    "era1970s",
  ],
  "austin-healey": [
    "100",
    "100-6",
    "3000",
    "bugeye sprite",
    "era1950s",
    "era1960s",
    "parts and automobilia",
    "sprite mk ii, iii, & iv",
  ],
  "auto union": ["era1950s"],
  autobianchi: ["a112", "bianchina", "era1970s", "era1980s"],
  autocar: ["era1940s"],
  autozam: ["az-1"],
  bac: ["mono"],
  "bac mono": ["bac mono"],
  benelli: ["era1960s", "era1970s", "era1980s", "era2000s"],
  bentley: [
    "3.5 litre & 4 1/4 litre",
    "arnage",
    "azure",
    "bentayga",
    "continental flying spur",
    "continental gt",
    "mark vi",
    "mulsanne (2011-2020)",
    "r-type",
    "s-type",
    "sz coupe",
    "sz saloon",
  ],
  berkeley: ["berkeley sports", "era1950s"],
  bertone: ["era1990s"],
  bimota: ["era1980s", "era1990s", "era2000s"],
  bmw: [
    "02 series cabriolet",
    "02 series touring",
    "1502",
    "1600 & 1602",
    "1802",
    "1m",
    "2-series",
    "2000c & 2000cs",
    "2002",
    "2002 turbo",
    "2002ti",
    "2002tii",
    "3.0 csl",
    "700",
    "alpina",
    "e12 5-series",
    "e21 3-series",
    "e23 7-series",
    "e24 6-series",
    "e24 m6 & m635csi",
    "e28 5-series",
    "e28 m5",
    "e3",
    "e30 3-series convertible",
    "e30 3-series coupe",
    "e30 3-series sedan",
    "e30 3-series touring",
    "e30 m3",
    "e31 8-series",
    "e32 7-series",
    "e34 5-series",
    "e34 m5",
    "e36 3-series",
    "e36 m3",
    "e36/5 ti compact",
    "e38 7-series",
    "e39 5-series",
    "e39 m5",
    "e46 3-series convertible",
    "e46 3-series coupe",
    "e46 3-series sedan",
    "e46 3-series touring",
    "e46 330 zhp",
    "e46 m3",
    "e60 5-series sedan",
    "e60 m5",
    "e61 5-series touring",
    "e63/e64 6-series",
    "e63/e64 m6",
    "e65/e66 7-series",
    "e82/e88 1-series",
    "e89 z4 roadster",
    "e9 coupe",
    "e90 3-series sedan",
    "e90/e92/e93 m3",
    "e91 3-series touring",
    "e92/e93 3-series coupe & convertible",
    "era1960s",
    "era2010s",
    "f01/f02 7-series",
    "f06/f12/f13 6-series",
    "f06/f12/f13 m6",
    "f10 5-series",
    "f10 m5",
    "f30 3-series sedan",
    "f31 3-series touring",
    "f32 4-series",
    "f34 3-series gran turismo",
    "f80 m3",
    "f82/f83 m4",
    "f90 m5",
    "g11/g12 7-series",
    "g1x/f9x 8-series",
    "g20 3-series",
    "g30 5-series",
    "g70 7-series",
    "g80 m3",
    "g82/g83 m4",
    "i8",
    "isetta",
    "m1",
    "m2",
    "motorcycles",
    "neue klasse",
    "parts and automobilia",
    "x3",
    "x5",
    "z1",
    "z3",
    "z3 m coupe",
    "z3 m roadster",
    "z4 coupe e86",
    "z4 m coupe",
    "z4 m roadster",
    "z4 roadster e85",
    "z8",
  ],
  bocar: ["era1950s"],
  borgward: ["era1950s", "era1960s"],
  "boss hoss": ["era1990s", "era2000s", "era2010s"],
  brabham: ["era1960s"],
  brewster: ["era1920s"],
  bricklin: ["parts and automobilia", "sv-1"],
  bristol: ["era1950s", "era1970s"],
  bsa: [
    "era1920s",
    "era1930s",
    "era1950s",
    "era1960s",
    "era1970s",
    "gold star",
    "lightning",
    "rocket",
  ],
  buell: ["era1990s", "era2000s", "era2020s"],
  bugatti: [
    "chiron",
    "era1920s",
    "era1930s",
    "parts and automobilia",
    "replica",
    "veyron",
  ],
  buggy: ["custom"],
  buick: [
    "centurion",
    "century",
    "electra",
    "era1970s",
    "gnx",
    "gran sport",
    "grand national",
    "gsx",
    "lesabre",
    "parts and automobilia",
    "reatta",
    "regal",
    "riviera",
    "roadmaster",
    "skylark",
    "special",
    "standard six & master six",
    "super",
    "wildcat",
  ],
  bultaco: ["alpina", "era1960s", "era1970s", "sherpa"],
  cadillac: [
    "allant\u00e9",
    "brougham",
    "ct4 & ct4-v",
    "ct5 & ct5-v",
    "cts & cts-v",
    "deville",
    "dts",
    "eldorado",
    "era1940s",
    "escalade & ext gmt800 (2002-2006)",
    "escalade & ext gmt900 (2007-2014)",
    "escalade gmt400 (1999-2000)",
    "escalade k2xl (2015-2020)",
    "escalade t1xl (2021-)",
    "fleetwood 1985-1996",
    "parts and automobilia",
    "series 61",
    "series 62",
    "series 75/fleetwood",
    "seville",
    "sixty special",
    "sts & sts-v",
    "v-12",
    "v8",
    "xlr",
  ],
  cagiva: ["era1980s", "era2000s"],
  caterham: ["era1990s", "seven"],
  checker: ["era1960s", "era1970s"],
  chevrolet: [
    "150, 210, & bel air (1953-1954)",
    "454 ss",
    "advance design pickup (1947-1955)",
    "ak series pickup (1941-1947)",
    "avalanche",
    "bel air",
    "biscayne",
    "blazer (2019+)",
    "blazer 1995-2004",
    "blazer gmt415",
    "c/k (1960-1966)",
    "c/k (1967-1972)",
    "c/k (1973-1991)",
    "c/k gmt400 (1988-2002)",
    "cadillac",
    "camaro 1st generation 1967-1969",
    "camaro 2nd generation 1970-1981",
    "camaro 3rd generation 1982-1992",
    "camaro 4th generation 1993-2002",
    "camaro 5th generation 2010-2015",
    "camaro 6th generation 2016-",
    "camaro 6th generation 2016-2024",
    "caprice",
    "carryall suburban (1935-1940)",
    "carryall suburban (1941-1946)",
    "cavalier",
    "chevelle",
    "colorado",
    "corvair (1960-1964)",
    "corvair (1965-1969)",
    "corvair greenbrier and corvan",
    "corvair rampside and loadside pickup",
    "corvette c1",
    "corvette c2",
    "corvette c3",
    "corvette c4",
    "corvette c5",
    "corvette c6",
    "corvette c7",
    "corvette c8",
    "corvette callaway",
    "dodge",
    "el camino",
    "era1920s",
    "era1930s",
    "era1960s",
    "era1980s",
    "era2010s",
    "express",
    "ford",
    "g-series van",
    "gmc",
    "hhr",
    "honda",
    "impala",
    "independence & confederate",
    "international harvester",
    "k5 blazer 1969-1972",
    "k5 blazer 1973-1991",
    "kodiak",
    "luv",
    "master, master deluxe, & special deluxe (1933-1942)",
    "monte carlo",
    "national, international, & universal",
    "nomad",
    "nova",
    "parts and automobilia",
    "pickup (1918-1932)",
    "pickup (1933-1940)",
    "s-10 (1982-1993)",
    "s-10 (1994-2004)",
    "s-10 blazer 1982-1994",
    "silverado gmt800 (1999-2007)",
    "silverado gmt800 heavy duty (2000-2007)",
    "silverado gmt900 (2007-2013)",
    "silverado gmt900 heavy duty (2007-2014)",
    "silverado gmt900 heavy duty (2007-2015)",
    "silverado k2xx (2014-2019)",
    "silverado t1xx (2019+)",
    "special, deluxe, & fleetline (1949-1952)",
    "ss sedan",
    "ssr",
    "standard, master, master deluxe, & special deluxe (1933-1942)",
    "stylemaster, fleetmaster, & fleetline (1946-1948)",
    "suburban (1947-1955)",
    "suburban (1955-1959)",
    "suburban (1960-1966)",
    "suburban (1967-1972)",
    "suburban (1973-1991)",
    "suburban (gmt400 1992-1999)",
    "suburban (gmt800 2000-2006)",
    "suburban (gmt900 2007-2014)",
    "tahoe gmt400",
    "tahoe gmt800",
    "tahoe gmt900",
    "task force pickup (1955-1959)",
    "trailblazer",
    "tri-5",
    "triumph",
    "vega",
  ],
  chevron: ["era1960s", "era1970s", "era1980s"],
  "chris craft": [
    "chris-craft 17\u2032 rocket runabout",
    "chris-craft 17\u2032 sportsman",
    "chris-craft capri 19\u2032",
  ],
  "chris-craft": [
    "era1920s",
    "era1930s",
    "era1940s",
    "era1950s",
    "era1960s",
    "era2010s",
  ],
  chrysler: [
    "& desoto airflow",
    "300 (2005-2023)",
    "300 letter series",
    "300 non-letter series (1962-1972)",
    "conquest & mitsubishi starion",
    "crossfire",
    "dodge",
    "era1930s",
    "imperial",
    "k-car",
    "lebaron",
    "new yorker",
    "newport",
    "parts and automobilia",
    "pt cruiser",
    "sno-runner",
    "tc by maserati",
    "town & country",
    "windsor",
  ],
  citroen: ["2cv", "cx", "ds", "mehari", "sm", "traction avant"],
  "classic motor carriages": ["era1920s", "era1980s", "era2020s"],
  confederate: ["era2000s", "era2010s"],
  cord: ["810/812", "century 21\u2032 coronado", "l-29", "replica"],
  crofton: ["era1950s"],
  crosley: ["era1930s", "era1940s", "era1950s"],
  cushman: ["eagle", "era1940s", "era1950s", "era1960s", "era1970s"],
  cz: ["era1960s", "era1970s"],
  daihatsu: ["era1970s", "era1980s", "era1990s", "hijet"],
  daimler: ["era1950s", "era1960s", "era1970s", "era1980s", "ferret", "sp250"],
  dallara: ["dallara f395", "era2000s", "era2010s"],
  datsun: [
    "240z",
    "260z",
    "280z",
    "280zx",
    "510",
    "parts and automobilia",
    "pickup",
    "roadster",
  ],
  delahaye: ["135", "era1930s", "era1940s"],
  delorean: ["dmc-12"],
  desoto: [
    "era1920s",
    "era1930s",
    "era1940s",
    "era1950s",
    "era1960s",
    "firedome",
  ],
  detomaso: ["mangusta", "pantera", "parts and automobilia"],
  "deutsch-bonnet/db": ["era1950s"],
  devin: ["era1950s", "era1960s"],
  "diamond t": ["era1930s", "era1940s", "era1950s"],
  dkw: ["era1930s", "era1950s", "era1960s", "era1970s", "tin banana"],
  dodge: [
    "& plymouth neon",
    "330, 440, & 880",
    "a100 pickup",
    "b-series (1948-1953)",
    "c-series (1954-1960)",
    "challenger",
    "challenger (1970-1974)",
    "challenger (non-srt, 2009-2023)",
    "challenger srt demon & super stock",
    "challenger srt hellcat",
    "challenger srt8 & 392",
    "charger",
    "coronet",
    "d-series (1961-1965)",
    "d-series (1965-1971)",
    "d-series (1972-1980)",
    "dakota (1987-1996)",
    "dakota (1997-2004)",
    "dakota (2005-2011)",
    "dart",
    "daytona & chrysler laser",
    "durango",
    "era1970s",
    "hc/kc pickup (1933-1935)",
    "l/m/r-series (1936-1938)",
    "lancer",
    "magnum",
    "military vehicles",
    "omni & plymouth horizon",
    "parts and automobilia",
    "pickup",
    "plymouth",
    "power wagon",
    "ram (1981-1993)",
    "ram (1994-2001)",
    "ram (2002-2010)",
    "ram (2009-2023)",
    "ram (2019+)",
    "ram srt-10 pickup",
    "ram trx",
    "ram van",
    "ramcharger / plymouth trail duster",
    "rampage and plymouth scamp",
    "royal",
    "shelby",
    "stealth",
    "super bee",
    "suzuki",
    "t/v/w-series (1939-1947)",
    "viper (2013-2017)",
    "viper sr i (1992-1995)",
    "viper sr ii (1996-2002)",
    "viper zb i (2003-2006)",
    "viper zb ii (2008-2010)",
  ],
  donzi: ["era1960s", "era1970s", "era1980s", "era1990s", "era2000s"],
  "dual-ghia": ["era1950s"],
  ducati: [
    "1098",
    "748",
    "750 & 900 super sport (1973-1982)",
    "750, 800, 900, & 1000 supersport (1988-2007)",
    "750gt",
    "851",
    "916",
    "996",
    "998",
    "999",
    "diavel",
    "era1960s",
    "era1970s",
    "era1980s",
    "era1990s",
    "era2000s",
    "era2010s",
    "era2020s",
    "hypermotard",
    "mh900e",
    "monster",
    "multistrada",
    "panigale",
    "paul smart",
    "sportclassic",
    "streetfighter",
    "superleggera",
    "supersport",
    "supersport (2021+)",
  ],
  duesenberg: ["era1920s"],
  edsel: ["era1950s", "era1960s"],
  elva: ["era1950s", "era1960s"],
  era1900s: ["originamerican", "partnerindianapolis motor speedway museum"],
  era1910s: ["originamerican", "partnerindianapolis motor speedway museum"],
  era1920s: ["originamerican", "partnerindianapolis motor speedway museum"],
  era1930s: [
    "categoryboats",
    "originamerican",
    "originbritish",
    "origingerman",
    "partnerindianapolis motor speedway museum",
  ],
  era1940s: [
    "originamerican",
    "originbritish",
    "partnerindianapolis motor speedway museum",
  ],
  era1950s: [
    "originamerican",
    "originbritish",
    "origingerman",
    "originitalian",
    "partneravants",
  ],
  era1960s: [
    "categoryminibikes & scooters",
    "originamerican",
    "originbritish",
    "origincanadian",
    "origingerman",
    "originitalian",
    "originjapanese",
    "partnerindianapolis motor speedway museum",
  ],
  era1970s: [
    "categorymilitary vehicles",
    "categoryminibikes & scooters",
    "originamerican",
    "originbrazilian",
    "originbritish",
    "origingerman",
    "originitalian",
  ],
  era1980s: [
    "originamerican",
    "originbritish",
    "originitalian",
    "partneravants",
    "partnerindianapolis motor speedway museum",
  ],
  era1990s: [
    "originamerican",
    "originbritish",
    "originitalian",
    "originjapanese",
  ],
  era2000s: ["originamerican", "originbritish", "originitalian"],
  era2010s: ["categoryall-terrain vehicles", "originamerican", "originitalian"],
  era2020s: [
    "categoryall-terrain vehicles",
    "originamerican",
    "origincanadian",
    "origingerman",
  ],
  excalibur: ["era1960s", "era1970s", "era1980s", "era1990s"],
  exomotive: ["era2010s", "era2020s"],
  "facel vega": ["era1950s", "era1960s"],
  "factory five": [
    "818",
    "daytona spyder gt",
    "era1930s",
    "era2010s",
    "gtm",
    "racing spyder gt",
  ],
  ferrari: [
    "250 gt",
    "296 gtb/gts",
    "308",
    "308 gt4",
    "328",
    "330 gt 2+2",
    "330 gtc",
    "348",
    "360",
    "365 gt 2+2",
    "365 gt4 2+2/400/400i/412",
    "365 gtc/4",
    "456",
    "458",
    "488",
    "550 maranello",
    "575m",
    "599 gtb & gto",
    "612 scaglietti",
    "812 superfast & 812 gts",
    "berlinetta boxer",
    "california",
    "daytona",
    "dino",
    "f12berlinetta",
    "f355",
    "f40",
    "f430",
    "f50",
    "f8",
    "ff",
    "fiat",
    "gtc4lusso",
    "la",
    "mondial",
    "parts and automobilia",
    "portofino",
    "replica",
    "roma",
    "sf90",
    "testarossa",
  ],
  fiat: [
    "1100",
    "124 sedan",
    "124 spider (1966-1985)",
    "124 spider (2017-2020)",
    "124 sport coupe",
    "126",
    "128",
    "130",
    "500 (2012-2019)",
    "500 jolly",
    "500 topolino",
    "600 jolly",
    "600 multipla",
    "600 sedan",
    "850",
    "dino",
    "era1960s",
    "era1970s",
    "era1980s",
    "ferrari",
    "nuova 500 (1957-1975)",
    "panda",
    "parts and automobilia",
    "pininfarina coupe and cabriolet",
    "x1/9",
  ],
  fiberfab: ["era1960s"],
  fisker: ["era2000s", "karma"],
  ford: [
    "18 & 40 v8 (1932-1934)",
    "48 & 68 v8 (1935-1936)",
    "a",
    "a pickup &  aa",
    "b",
    "bronco 2nd generation 1978-1979",
    "bronco 3rd generation 1980-1986",
    "bronco 4th-5th generations 1987-1996",
    "bronco ii",
    "bronco u13/u14/u15 1966-1977",
    "bronco u725",
    "contour",
    "cortina",
    "country sedan",
    "country squire",
    "courier",
    "crestline",
    "crown victoria",
    "customline",
    "econoline pickup",
    "econoline van",
    "era1930s",
    "era1940s",
    "era1980s",
    "era1990s",
    "escort mk1",
    "escort rs cosworth",
    "excursion",
    "expedition",
    "explorer",
    "explorer sport trac",
    "f-150 (1997-2004)",
    "f-150 (2004-2008)",
    "f-150 (2009-2014)",
    "f-150 (2015-2020)",
    "f-150 (2021+)",
    "f-150 lightning electric",
    "f-150 raptor",
    "f-150 svt lightning",
    "f-series 1948-1952",
    "f-series 1953-1956",
    "f-series 1957-1960",
    "f-series 1961-1966",
    "f-series 1967-1972",
    "f-series 1973-1979",
    "f-series 1980-1986",
    "f-series 1987-1991",
    "f-series 1992-1997",
    "f-series 1997-2004",
    "f-series 2004-2008",
    "f-series 2009-2014",
    "f-series 2015-2020",
    "f-series 2021-",
    "fairlane",
    "falcon",
    "fiesta",
    "focus",
    "fox-body mustang",
    "galaxie",
    "gt 1st generation",
    "gt 2nd generation",
    "gt40 replica",
    "honda",
    "hot rod",
    "jeep",
    "lotus",
    "ltd",
    "maverick",
    "military",
    "mustang 1964.5-1966",
    "mustang 1967-1968",
    "mustang 1969-1973",
    "mustang ii",
    "mustang mach-e",
    "mustang s197 2005-2014",
    "mustang s550",
    "mustang s650",
    "mustang sn95 1994-2004",
    "p703 ranger (2024+)",
    "p708 f-series super duty (2023-)",
    "parts and automobilia",
    "pickup 1932-1934 &  bb",
    "pickup 1935-1936",
    "pickup 1937-1939",
    "pickup 1940-1947",
    "pinto",
    "ranchero",
    "ranger 1983-1992",
    "ranger 1993-1997",
    "ranger 1998-2011",
    "ranger 2019-2023",
    "roush",
    "rs200",
    "saleen",
    "shelby",
    "shelby mustang (2006+)",
    "shoebox",
    "standard, deluxe, & super deluxe",
    "super duty 1999-2007",
    "super duty 2008-2010",
    "super duty 2011-2016",
    "super duty 2017-2022",
    "t",
    "t pickup &  tt",
    "taurus race car",
    "taurus sedan & wagon",
    "taurus sho",
    "thunderbird (1955-1957)",
    "thunderbird (1958-1960)",
    "thunderbird (1961-1963)",
    "thunderbird (1964-1966)",
    "thunderbird (1967-1971)",
    "thunderbird (1972-1976)",
    "thunderbird (1977-1979)",
    "thunderbird (1980-1982)",
    "thunderbird (1983-1988)",
    "thunderbird (1989-1997)",
    "thunderbird (2002-2005)",
    "torino",
    "tractor",
    "transit",
  ],
  freightliner: ["era1990s", "era2000s", "era2010s", "era2020s"],
  fuji: ["era1960s", "go-devil"],
  "gar wood": ["era1920s", "era1930s", "era1940s", "era1960s"],
  gaz: ["era1960s", "era1970s", "gaz volga 21"],
  geo: ["peugeot 205 gti 1.9l", "tracker/suzuki sidekick"],
  gilera: ["era1940s", "era1950s", "era1960s"],
  ginetta: [
    "era1960s",
    "era1990s",
    "era2000s",
    "era2010s",
    "osca 1600 gtz berlinetta by zagato",
  ],
  gmc: [
    "blue chip pickup (1955-1959)",
    "c-series pickup (1941-1945)",
    "c/k 1960-1966",
    "c/k 1967-1972",
    "c/k 1973-1991",
    "caballero",
    "canyon",
    "carryall suburban (1947-1955)",
    "chevrolet",
    "e-series pickup (1946-1947)",
    "era1970s",
    "gmt900 yukon",
    "hummer ev",
    "jimmy (1970-1972)",
    "jimmy (1973-1991)",
    "jimmy (1995-2005)",
    "motorhome",
    "new design pickup (1947-1955)",
    "s-15 jimmy",
    "s-15 sonoma (1982-1993)",
    "sierra (gmt400 1988-2002)",
    "sierra (gmt800 1999-2007)",
    "sierra (gmt900 2007-2013)",
    "sierra (k2xx 2014-2019)",
    "sierra (t1xx 2019+)",
    "sierra hd (gmt800 2000-2007)",
    "sierra hd (gmt900 2007-2014)",
    "sierra hd (gmt900 2007-2015)",
    "sonoma (1994-2004)",
    "sprint",
    "suburban (1955-1959)",
    "suburban (1960-1966)",
    "suburban (1967-1972)",
    "suburban (1973-1991)",
    "suburban (gmt400 1992-1999)",
    "syclone",
    "topkick",
    "typhoon",
    "van",
    "yukon gmt400",
    "yukon gmt800",
    "yukon k2xx (2015-2020)",
    "yukon t1xx (2021+)",
  ],
  goggomobil: [
    "era1950s",
    "era1960s",
    "goggomobil dart",
    "goggomobil ts250 coupe",
  ],
  graham: ["era1920s", "era1930s", "era1940s"],
  griffith: ["era1960s"],
  "harley-davidson": [
    "45 flathead (1929-1975)",
    "big-twin flathead (1930-1948)",
    "cvo",
    "dyna",
    "era1910s",
    "era1920s",
    "era1930s",
    "era1940s",
    "era1950s",
    "era1960s",
    "era1970s",
    "era1980s",
    "era1990s",
    "era2000s",
    "era2010s",
    "era2020s",
    "fxr",
    "knucklehead",
    "originamerican",
    "panhead",
    "servi-car",
    "shovelhead",
    "softail",
    "sportster",
    "sprint",
    "touring",
    "v-rod",
  ],
  healey: ["era1940s", "era1950s"],
  heinkel: ["era1950s", "era1960s"],
  hennessey: ["era2020s"],
  "henry j": ["era1950s"],
  hillman: ["era1940s", "era1950s", "era1960s"],
  "hispano suiza": ["era1920s"],
  hmv: ["era1980s"],
  hodaka: ["era1960s", "era1970s"],
  honda: [
    "accord",
    "acty",
    "acura",
    "ascot",
    "atv",
    "beat",
    "benly",
    "cb1000 & cb1100",
    "cb125 & cl125",
    "cb175 & cl175",
    "cb200 & cl200",
    "cb350 & cl350",
    "cb350f & cb400f",
    "cb360 & cl360",
    "cb450, cl450, & cb500 twin",
    "cb500 four & cb550",
    "cb650",
    "cb750 & cb750a",
    "cbr",
    "cbx",
    "city",
    "civic (1972-1979)",
    "civic (1980-1983)",
    "civic (1984-1987)",
    "civic (1988-1991)",
    "civic (1992-1995)",
    "civic (1996-2000)",
    "civic (2001-2005)",
    "civic (2006-2011)",
    "civic (2012-2015)",
    "civic (2016-2021)",
    "civic (2022+)",
    "civic del sol",
    "civic si",
    "civic type r",
    "cl70 & cl90",
    "cl72, cb72, ca72, & c72",
    "cr & crf",
    "cr-v",
    "crx",
    "ct110",
    "ct70",
    "ct90",
    "custom: cb650c, cb750c, cb900c, & cb1000c",
    "cx",
    "dream, cb77 super hawk, & cl77 scrambler",
    "element",
    "elsinore",
    "era1970s",
    "era1990s",
    "ez90 cub",
    "gb500 tourist trophy",
    "gold wing",
    "grom",
    "jeep",
    "magna",
    "mopeds",
    "motocompo & motocompacto",
    "motorcycles",
    "n360 & n600",
    "nighthawk",
    "nsr, nsf, & dream cb50r",
    "nt series",
    "other motorcycles",
    "parts and automobilia",
    "pickup",
    "prelude",
    "qa50",
    "rc51",
    "reflex",
    "rune",
    "s2000",
    "s600 & s800",
    "s90",
    "sabre",
    "sl motosport",
    "st90",
    "step-through scooter",
    "super sport: cb750f, cb900f, & cb1100f",
    "tl125 & tl250",
    "vf & vfr",
    "xl & xr",
    "z50",
    "z600",
  ],
  hudson: [
    "era1920s",
    "era1930s",
    "era1940s",
    "era1950s",
    "hornet",
    "super six",
  ],
  hummer: [
    "era1990s",
    "era2000s",
    "h1",
    "h2",
    "h3",
    "hmmwv/humvee",
    "parts and automobilia",
  ],
  hupmobile: ["era1910s", "era1920s", "era1930s"],
  husqvarna: ["era1960s", "era1970s", "era1980s", "era2010s", "wr"],
  hyundai: ["era2000s", "era2010s", "era2020s", "hyundai xg350l"],
  indian: ["chief", "scout"],
  ineos: ["grenadier"],
  infiniti: [
    "era1990s",
    "era2000s",
    "era2010s",
    "g35 & g37",
    "infiniti m30 convertible",
  ],
  innocenti: ["era1960s", "era1970s", "era1980s"],
  intermeccanica: ["era1960s", "era1970s", "era1980s", "era2000s", "italia"],
  "international harvester": ["loadstar", "pickup", "scout", "travelall", "xt"],
  iso: ["era1960s", "era1970s", "kellison j6 panther"],
  isuzu: ["elf", "trooper", "vehicross"],
  jaguar: [
    "chevrolet",
    "f-type",
    "mk i",
    "mk ii",
    "mk iv",
    "mk vii, viii, & ix",
    "parts and automobilia",
    "replica",
    "s-type (1963-1968)",
    "s-type (1999-2007)",
    "x-type",
    "x300 xj (1995-1997)",
    "x306 xjr (1995-1997)",
    "x308 xj (1998-2003)",
    "x308 xjr (1998-2003)",
    "x350/x356/x358 xj (2004-2009)",
    "x350/x356/x358 xjr (2004-2009)",
    "x351 xj (2010-2019)",
    "xf",
    "xj",
    "xj coupe (1975-1978)",
    "xj series 1 (1968-1973)",
    "xj series 2 (1974-1979)",
    "xj series 3 (1980-1992)",
    "xj220",
    "xj40 xj (1988-1994)",
    "xjs",
    "xk",
    "xk120",
    "xk140",
    "xk150",
    "xk8",
    "xke series i",
    "xke series ii",
    "xke series iii",
    "xkr",
  ],
  jawa: ["era1950s", "era1960s", "era1970s"],
  jeep: [
    "cherokee",
    "cj-5",
    "cj-6",
    "cj-7",
    "cj-8",
    "era1960s",
    "forward control",
    "grand cherokee",
    "grand wagoneer",
    "parts and automobilia",
    "pickup",
    "sj cherokee",
    "sj grand wagoneer",
    "sj wagoneer",
    "ster commando",
    "willys",
    "wj grand cherokee",
    "wk grand cherokee",
    "wk2 grand cherokee",
    "wrangler jk (2007-2018)",
    "wrangler jl (2018+)",
    "wrangler tj (1997-2006)",
    "wrangler yj (1987-1995)",
    "xj cherokee",
    "xj wagoneer",
    "zj grand cherokee",
    "zj grand wagoneer",
  ],
  jensen: ["interceptor", "\u2013 healey"],
  "john deere": ["era1930s", "era1940s", "era1950s"],
  jowett: ["era1950s"],
  kaiser: ["darrin", "era1940s", "era1950s", "era1960s"],
  kawasaki: [
    "era1960s",
    "era1970s",
    "era1980s",
    "era1990s",
    "era2000s",
    "h1 mach iii",
    "h2 mach iv",
    "kz",
    "ninja",
    "parts and automobilia",
    "z1 & z1-r",
  ],
  kellison: ["era1950s", "era1960s"],
  "king midget": ["era1940s", "era1950s"],
  koenigsegg: ["era2020s"],
  ktm: ["era1970s", "era1980s", "era1990s", "era2000s", "era2010s", "era2020s"],
  kurtis: ["era1940s", "era1950s"],
  lada: ["era1980s", "era1990s"],
  lagonda: ["era1930s", "era1950s", "era2010s"],
  lamborghini: [
    "350 gt & 400 gt",
    "aventador",
    "countach",
    "diablo",
    "espada",
    "gallardo",
    "hurac\u00e1n",
    "jalpa",
    "jarama",
    "lm002",
    "murci\u00e9lago",
    "parts and automobilia",
    "tractor",
    "urraco",
    "urus",
  ],
  lambretta: ["era1940s", "era1950s", "era1960s"],
  lancia: [
    "aurelia",
    "beta",
    "delta",
    "era1940s",
    "era1960s",
    "flaminia",
    "flavia",
    "fulvia",
    "parts and automobilia",
    "scorpion / montecarlo",
  ],
  "land rover": [
    "110 / defender 110 (euro spec)",
    "127 / defender 130",
    "90 / defender 90 (euro spec)",
    "defender 110 nas",
    "defender 90 nas",
    "defender l663",
    "discovery",
    "era1960s",
    "era1970s",
    "era1980s",
    "era1990s",
    "era2000s",
    "era2010s",
    "l461 range rover sport",
    "l538 range rover evoque",
    "lr3",
    "lr4",
    "parts and automobilia",
    "range rover classic",
    "range rover l322",
    "range rover l405",
    "range rover l460",
    "range rover p38a",
    "range rover sport l320",
    "range rover sport l494",
    "series 109",
    "series 88",
  ],
  lasalle: ["era1920s", "era1930s", "era1940s", "series 50"],
  laverda: ["era1970s", "era1980s", "sf"],
  lexus: [
    "era2020s",
    "es",
    "gs",
    "gx",
    "is",
    "lc",
    "lfa",
    "ls sedan",
    "lx (j310)",
    "lx450",
    "lx470",
    "lx570",
    "rc",
    "sc",
    "toyota",
  ],
  leyland: ["era1970s"],
  lincoln: [
    "blackwood & mark lt",
    "capri",
    "continental (1940-1948)",
    "continental (1958-1960)",
    "continental (1961-1969)",
    "continental (1970-1979)",
    "continental (1982-1987)",
    "continental (1995-2002)",
    "continental (2017-2020)",
    "continental mark ii",
    "continental mark iii (1969-1971)",
    "continental mark iii (1969-1972)",
    "continental mark iv (1972-1976)",
    "continental mark v (1977-1979)",
    "continental mark vi (1980-1983)",
    "continental mark vii & mark vii",
    "mark viii",
    "navigator",
    "town car",
  ],
  "local motors": ["era2010s"],
  "locationlocated in canada": ["categoryminibikes & scooters"],
  locomobile: ["era1910s", "era1920s"],
  lola: ["era1960s", "era1970s", "era1980s", "era1990s", "era2000s"],
  lotus: [
    "2-eleven & 3-eleven",
    "cortina",
    "elan (1962-1975)",
    "elan m100",
    "elise",
    "elite",
    "emira",
    "era1950s",
    "era1970s",
    "esprit",
    "europa",
    "evora",
    "exige",
    "open-wheel",
    "other seven replicas",
    "parts and automobilia",
    "seven & caterham seven",
    "seven & super seven",
    "westfield eleven xi",
  ],
  lti: ["era2000s", "lti txii london taxi burberry edition", "ultima gtr"],
  lucid: ["air"],
  mack: ["era1920s", "era1950s", "era1960s", "mack b95 fire truck"],
  mahindra: ["era1970s", "era2010s", "era2020s"],
  maico: ["era1950s", "era1960s", "era1970s", "era1980s"],
  mallock: ["era1970s", "era2010s"],
  march: ["era1970s", "era1980s", "era1990s", "originamerican"],
  marcos: ["era1990s", "gt"],
  marmon: ["era1920s", "era1930s"],
  maserati: [
    "3500 gt",
    "biturbo",
    "bora",
    "coupe & spyder",
    "ghibli",
    "granturismo",
    "mc20",
    "merak",
    "mistral",
    "parts and automobilia",
    "quattroporte",
  ],
  matchless: ["era1940s", "era1950s", "era1960s"],
  matra: ["era1960s", "era1980s"],
  mauck: ["era1990s", "era2000s"],
  maxton: ["era1990s"],
  maxwell: ["era1900s", "era1910s", "era1920s"],
  maybach: ["57", "era2000s"],
  mazda: [
    "3 & speed3",
    "6 & speed6",
    "cosmo",
    "era2010s",
    "na mx-5 miata",
    "nb mx-5 miata",
    "nc mx-5 miata",
    "nd mx-5 miata",
    "pickup",
    "rx-7 fb",
    "rx-7 fc",
    "rx-7 fd",
    "rx-8",
  ],
  mclaren: [
    "gt",
    "mp4-12c",
    "parts and automobilia",
    "racing",
    "sports series",
    "super series",
    "ultimate series",
  ],
  "mercedes-benz": [
    "190e 2.3-16 & 2.5-16",
    "190sl",
    "300sl gullwing & roadster",
    "450sel 6.9",
    "500e & e500",
    "600",
    "amg",
    "amg gt",
    "brabus",
    "c107 slc",
    "c126",
    "c140",
    "c207 e-class coupe/cabriolet",
    "c217 s-class coupe",
    "cl-class",
    "clk-class",
    "clk63 amg black series",
    "cls-class",
    "era2010s",
    "era2020s",
    "gl & gls-class",
    "m-class & gle-class",
    "parts and automobilia",
    "ponton",
    "puch",
    "r107 sl",
    "r129 sl",
    "r230 sl",
    "r231 sl",
    "r232 sl",
    "r63 amg",
    "slk-class",
    "slr mclaren",
    "sls amg",
    "sprinter",
    "unimog",
    "w108 & w109",
    "w110",
    "w111 coupe & cabriolet",
    "w111 sedan",
    "w112",
    "w113 sl",
    "w114 & w115",
    "w116 s-class",
    "w123",
    "w124 e-class",
    "w126 s-class",
    "w140 s-class",
    "w186 & w189 adenauer",
    "w187 220",
    "w191 & w136",
    "w201 190",
    "w202 c-class",
    "w202 c36 & c43 amg",
    "w203 c-class",
    "w203 c55 & c32 amg",
    "w204 c-class",
    "w204 c63 amg",
    "w205 c-class",
    "w205 c43 & c63 amg",
    "w210 e-class",
    "w210 e-class amg",
    "w211 e-class",
    "w211 e-class amg",
    "w212 e-class",
    "w212 e-class amg",
    "w213 e-class",
    "w213 e-class amg",
    "w220 s-class",
    "w221 s-class",
    "w222 s-class",
    "w223 s-class",
    "w251 r-class",
    "w460 gel\u00e4ndewagen",
    "w461 g-class",
    "w463 g-class (1990-2018)",
    "w463 g-class (2019+)",
    "w463 g-class amg (1990-2018)",
    "w463 g-class amg (2019+)",
  ],
  mercury: [
    "capri",
    "colony park",
    "comet",
    "cougar",
    "cyclone",
    "eight",
    "era1960s",
    "marauder",
    "marquis & grand marquis",
    "monterey",
    "parts and automobilia",
  ],
  merkur: ["era1980s", "xr4ti"],
  messerschmitt: ["era1950s", "era1960s", "kabinenroller & fmr tg500"],
  meteor: ["era1950s"],
  meyers: ["manx", "parts and automobilia"],
  mg: [
    "a",
    "austin-healey",
    "b",
    "c",
    "era1950s",
    "magnette",
    "midget",
    "mini",
    "parts and automobilia",
    "ta",
    "tc",
    "td",
    "tf",
  ],
  mini: [
    "classic",
    "era1960s",
    "f55 & f56 cooper, cooper s, & john cooper works",
    "mk i & mk ii",
    "mk i-iii cooper",
    "mk iii-mk v",
    "mk vi & mk vii",
    "modern",
    "moke",
    "moke classic",
    "moke electric",
    "parts and automobilia",
    "r50 cooper",
    "r52 cooper convertible",
    "r53 cooper s",
    "r53 jcw gp",
    "r53 john cooper works",
    "r55 clubman",
    "r56 cooper, cooper s, and john cooper works",
    "r56 jcw gp",
    "r57 convertible",
    "r58 & r59 coupe & roadster",
    "r60 countryman",
  ],
  mitsubishi: [
    "3000gt",
    "canter",
    "delica",
    "eclipse, eagle talon, & plymouth laser",
    "lancer evolution",
    "minicab",
    "pajero & montero",
  ],
  montesa: ["era1970s", "era1980s"],
  moretti: ["era1940s", "era1970s"],
  morgan: ["3-wheeler", "4/4", "aero 8", "plus 4", "plus 8", "v6 roadster"],
  morris: ["minor"],
  mosler: ["era1990s", "era2000s", "mosler consulier gtp sport c4"],
  "moto guzzi": [
    "ambassador",
    "eldorado",
    "era1930s",
    "era1960s",
    "era1970s",
    "era1980s",
    "era1990s",
    "era2000s",
    "era2010s",
    "le mans",
  ],
  motobecane: ["era1970s", "era1980s"],
  muntz: ["era1950s"],
  "mv agusta": ["era1950s", "era1970s", "era2000s", "era2010s", "f4"],
  nash: ["era1930s", "era1950s", "era1960s", "metropolitan"],
  nissan: [
    "240sx",
    "350z",
    "370z",
    "c10 skyline",
    "c110 skyline",
    "c210 skyline",
    "era1980s",
    "era1990s",
    "figaro",
    "gt-r r35",
    "maxima",
    "pao",
    "pathfinder",
    "patrol",
    "pickup",
    "r30 skyline",
    "r31 skyline",
    "r32 skyline",
    "r33 skyline",
    "r34 skyline",
    "rz34 z",
    "sentra se-r",
    "silvia",
    "skyline (s50-series)",
    "xterra",
    "z31 300zx",
    "z32 300zx",
  ],
  noble: ["era2000s", "m12"],
  norton: ["atlas", "commando", "era1930s", "era1940s", "era1950s", "era1960s"],
  nsu: ["era1930s", "era1940s", "era1950s", "era1960s", "era1970s"],
  oldsmobile: [
    "4-4-2",
    "442",
    "88",
    "98",
    "custom cruiser",
    "cutlass",
    "f-85 & jetfire",
    "hurst/olds",
    "parts and automobilia",
    "starfire",
    "toronado",
    "vista cruiser",
  ],
  opel: ["coupe", "gt"],
  "origin-american": ["categoryboats", "categoryminibikes & scooters"],
  osca: ["era1950s"],
  osprey: ["defender replica"],
  owosso: ["era1980s"],
  packard: ["clipper", "eight", "six, one-ten, & 115", "twelve"],
  pagani: ["era2010s"],
  paige: ["era1910s", "era1920s"],
  panhard: ["era1910s", "era1950s", "era1960s"],
  panoz: ["esperante", "roadster"],
  "parts and automobilia": [
    "acura",
    "alfa romeo",
    "aston martin",
    "austin-healey",
    "bentley",
    "bmw",
    "bugatti",
    "buick",
    "cadillac",
    "chevrolet",
    "datsun",
    "delorean",
    "detomaso",
    "dodge",
    "ferrari",
    "fiat",
    "ford",
    "harley-davidson",
    "honda",
    "hummer",
    "jaguar",
    "lamborghini",
    "lancia",
    "land rover",
    "lincoln",
    "lotus",
    "maserati",
    "maybach",
    "mazda",
    "mclaren",
    "mercedes-benz",
    "mercury",
    "morgan",
    "nissan",
    "oldsmobile",
    "packard",
    "plymouth",
    "pontiac",
    "porsche",
    "renault",
    "saab",
    "shelby",
    "studebaker",
    "toyota",
    "triumph",
    "volkswagen",
    "willys",
    "\u2013 artwork",
    "\u2013 bodywork",
    "\u2013 competition go-karts",
    "\u2013 engines",
    "\u2013 furniture",
    "\u2013 gearboxes",
    "\u2013 golf carts",
    "\u2013 literature & memorabilia",
    "\u2013 luggage",
    "\u2013 mechanical components",
    "\u2013 mini cars",
    "\u2013 pedal cars",
    "\u2013 racing simulators",
    "\u2013 rolling chassis",
    "\u2013 seats & interior",
    "\u2013 sidecars",
    "\u2013 signs",
    "\u2013 timers",
    "\u2013 tools",
    "\u2013 trailers",
    "\u2013 wheels: bbs",
    "\u2013 wheels: borrani",
    "\u2013 wheels: campagnolo",
    "\u2013 wheels: cromodora",
    "\u2013 wheels: fuchs",
    "\u2013 wheels: kelsey-hayes",
    "\u2013 wheels: kpz",
    "\u2013 wheels: other",
    "\u2013 wheels: porsche",
    "\u2013 wheels: ronal",
    "\u2013 wheels: speedline",
  ],
  peerless: ["era1920s", "era1950s"],
  peugeot: ["205", "era1950s", "era1960s", "era1970s", "era1980s", "era1990s"],
  piaggio: ["era1960s", "era1970s", "era1980s", "era1990s", "era2000s"],
  "pierce  arrow": ["era1920s"],
  "pierce-arrow": ["era1920s", "era1930s"],
  pininfarina: ["era1980s"],
  pinzgauer: ["era1970s", "era1980s"],
  plymouth: [
    "barracuda",
    "belvedere",
    "deluxe/special deluxe",
    "duster",
    "fury",
    "gtx",
    "parts and automobilia",
    "prowler",
    "road runner",
    "satellite",
    "suburban",
    "superbird & dodge charger daytona",
    "valiant",
    "volare",
  ],
  polaris: [
    "era1960s",
    "era1970s",
    "era1980s",
    "era1990s",
    "era2010s",
    "era2020s",
  ],
  polestar: ["era2020s"],
  pontiac: [
    "bonneville",
    "catalina",
    "chieftain",
    "era1960s",
    "fiero",
    "firebird (1967-1969)",
    "firebird (1970-1981)",
    "firebird (1982-1992)",
    "firebird (1993-2002)",
    "g8",
    "grand prix",
    "grand ville",
    "gto",
    "parisienne",
    "parts and automobilia",
    "safari",
    "solstice",
    "star chief",
    "tempest / lemans",
  ],
  porsche: [
    "356",
    "356 carrera",
    "356 pre-a",
    "356 replica",
    "356 speedster",
    "356a",
    "356b",
    "356c",
    "550 spyder replica",
    "911 1974-1977",
    "911 carrera 3.2",
    "911 carrera rs (1973)",
    "911 rs america",
    "911 safari",
    "911 speedster",
    "911sc",
    "912",
    "912e",
    "914 1.7/1.8/2.0",
    "914/6",
    "918 spyder",
    "924",
    "928",
    "930 turbo",
    "934 & 935",
    "944",
    "944 turbo",
    "955/957 cayenne (2003-2010)",
    "956 & 962",
    "958 cayenne (2011-2018)",
    "959",
    "964 911 (non-turbo)",
    "964 carrera rs",
    "964 turbo",
    "968",
    "981 boxster",
    "981 cayman",
    "982 718 boxster",
    "982 718 cayman",
    "986 boxster",
    "987 boxster",
    "987 cayman",
    "991 911 (non-turbo/gt2/gt3)",
    "991 gt2",
    "991 gt3",
    "991 turbo",
    "992 911 (non-turbo/gt2/gt3)",
    "992 gt3",
    "992 turbo",
    "993 911 (non-turbo/gt2)",
    "993 gt2",
    "993 turbo",
    "996 911 (non-turbo/gt2/gt3)",
    "996 gt2",
    "996 gt3",
    "996 turbo",
    "997 911 (non-turbo/gt2/gt3)",
    "997 gt2",
    "997 gt3",
    "997 turbo",
    "9y0/9y3 cayenne (2019-)",
    "carrera gt",
    "cayman gt4",
    "emory outlaw",
    "era1950s",
    "longhood 911",
    "lwb 911e (1969-1973)",
    "lwb 911s (1969-1973)",
    "lwb 911t (1969-1973)",
    "macan",
    "panamera",
    "parts and automobilia",
    "singer",
    "sports prototypes (1960s & 1970s)",
    "swb 911 (1965-1968)",
    "taycan",
    "tractor",
  ],
  puch: ["era1960s", "era1970s", "era1980s", "mercedes-benz", "moped"],
  puma: ["era1970s", "era1980s"],
  quadro: ["era2020s"],
  qvale: ["mangusta"],
  radical: ["era2000s", "era2010s", "era2020s", "sr3"],
  rambler: ["american", "classic", "era1950s", "era1960s"],
  renault: ["4cv", "dauphine", "parts and automobilia", "r5 turbo"],
  reo: ["era1920s", "era1930s", "era1940s"],
  reynard: ["era1970s", "era1980s", "era1990s"],
  rickman: ["era1960s", "era1970s"],
  riley: ["era1920s", "era1930s", "era1940s", "era1950s", "era1960s"],
  rivian: ["r1s", "r1t"],
  rokon: ["era1960s", "era1970s"],
  "rolls-royce": [
    "20/25",
    "40/50 silver ghost",
    "corniche",
    "cullinan",
    "era1930s",
    "ghost",
    "parts and automobilia",
    "phantom coupe & drophead coupe",
    "phantom i, ii, & iii",
    "phantom vii & viii",
    "silver cloud & phantom v",
    "silver seraph",
    "sy silver shadow & silver wraith ii",
    "sz silver spirit & silver spur",
    "wraith",
  ],
  rossion: ["era2000s", "era2010s"],
  rover: [
    "cooper",
    "era1940s",
    "era1950s",
    "era1960s",
    "era1970s",
    "era1980s",
    "era1990s",
  ],
  "royal enfield": [
    "era1930s",
    "era1950s",
    "era1960s",
    "era1970s",
    "era2000s",
    "era2010s",
    "era2020s",
  ],
  rudge: ["era1930s"],
  rupp: ["era1960s", "era1970s"],
  saab: [
    "9-3",
    "9-3 viggen",
    "9-5",
    "900",
    "9000",
    "95 wagon",
    "96",
    "99",
    "parts and automobilia",
    "sonett",
  ],
  saturn: ["sky"],
  scion: ["era2000s", "era2010s"],
  "sears/allstate": ["era1950s", "era1960s"],
  shelby: [
    "cobra mk i, ii, & iii",
    "cobra replica",
    "csx cobra continuation",
    "daytona coupe replica",
    "era1960s",
    "ford",
    "mustang gt350 & gt500",
    "parts and automobilia",
    "series 1",
  ],
  siata: ["era1950s", "era1960s"],
  simca: ["era1940s", "era1950s", "era1960s", "era1970s"],
  singer: ["era1930s", "era1940s", "era1950s"],
  skoda: ["era1990s"],
  spartan: ["era1950s"],
  spyker: ["c8"],
  standard: ["era1930s", "era1940s"],
  sterling: ["era1970s", "era1980s", "era1990s"],
  steyr: ["era1930s", "era1960s", "era1970s"],
  "steyr-puch": ["pinzgauer"],
  studebaker: [
    "avanti",
    "champion",
    "commander",
    "era1920s",
    "hawk",
    "lark",
    "pickup",
    "president",
  ],
  stutz: ["era1920s", "era1930s", "era1970s", "era1980s"],
  subaru: [
    "360",
    "baja",
    "brat",
    "brz",
    "forester",
    "impreza",
    "impreza 2.5rs",
    "impreza wrx",
    "legacy",
    "outback",
    "sambar",
    "svx",
    "wrx sti",
  ],
  sunbeam: ["alpine", "era1950s", "parts and automobilia", "tiger"],
  superformance: ["era1960s"],
  superlite: ["era2010s", "race car replicas superlite razor"],
  suzuki: ["carry", "era1990s", "jimny", "motorcycles", "samurai"],
  swift: ["era1980s", "era1990s"],
  talbot: ["era1920s", "era1930s", "era1940s", "era1980s"],
  tatra: ["era1950s", "era1970s", "tatra t-2 603"],
  tesla: ["cybertruck", "era2010s", "roadster", "s"],
  toyota: [
    "2000gt",
    "4runner (n120)",
    "4runner (n180)",
    "4runner (n210)",
    "4runner (n280)",
    "4runner (n60)",
    "ae86",
    "aw11 mr2",
    "camry",
    "celica",
    "celica supra a40/a50 (1979-1981)",
    "celica supra a60 (1982-1986)",
    "century",
    "corolla",
    "corona",
    "cressida & mark ii",
    "crown",
    "dyna",
    "era1960s",
    "era1990s",
    "fj cruiser",
    "hiace",
    "hilux (n10 1968-1972)",
    "hilux/pickup (n20 1972-1978)",
    "honda",
    "land cruiser 100-series",
    "land cruiser 200-series",
    "land cruiser 60-series",
    "land cruiser 70-series",
    "land cruiser 80-series",
    "land cruiser fj25",
    "land cruiser fj40",
    "land cruiser fj43",
    "land cruiser fj45",
    "land cruiser fj55",
    "land cruiser j250",
    "land cruiser prado 70-series",
    "land cruiser prado 90-series",
    "lexus",
    "liteace & townace",
    "parts and automobilia",
    "pickup (n30/n40 1978-1983)",
    "pickup (n50/n60/n70 1984-1988)",
    "pickup (n80/90/100/110 1989-1997)",
    "rav4",
    "sequoia",
    "stout",
    "supra a70 (1986-1992)",
    "supra a80 (1993-2002)",
    "supra j29/db (2020-)",
    "sw20 mr2",
    "t100",
    "tacoma (n1x0 1995-2005)",
    "tacoma (n2x0 2005-2015)",
    "tacoma (n300 2016-2023)",
    "toyoace",
    "trekker",
    "tundra (xk30/xk40 2000-2006)",
    "tundra (xk50 2007-2021)",
    "tundra (xk70 2022+)",
    "w30 mr2",
  ],
  triton: ["era1950s", "era1960s", "originbritish"],
  triumph: [
    "1800 & 2000 roadster",
    "5ta speed twin",
    "bonneville t120",
    "bonneville t140",
    "daytona",
    "gt6",
    "military motorcycle",
    "motorcycles",
    "new bonneville (2000+)",
    "parts and automobilia",
    "scrambler",
    "speed twin 900 & 1200",
    "spitfire",
    "stag",
    "thruxton",
    "thunderbird (1949-1966)",
    "tiger (1939-1973)",
    "tiger (1990s+)",
    "tr2",
    "tr3",
    "tr4",
    "tr5 & tr250",
    "tr6",
    "trident",
    "trophy",
    "wedge",
  ],
  turner: ["era1950s", "era1960s"],
  tvr: ["2500m", "cerbera", "era1990s"],
  "twin coach": ["era1930s"],
  ultima: ["era1990s", "era2000s", "era2010s"],
  ural: ["era2000s", "era2010s", "era2020s", "lm user\u2019s manual"],
  "ural-": ["era2010s"],
  vanderhall: ["era2010s", "era2020s"],
  vauxhall: ["era1920s", "era1950s", "era1980s"],
  vector: ["era1990s"],
  velocette: ["era1930s", "era1940s", "era1950s", "era1960s", "era1970s"],
  venturi: ["era1990s"],
  vespa: ["era1950s", "era1960s", "era1970s", "era1980s", "era2000s"],
  vincent: ["black shadow", "comet", "rapide"],
  volkswagen: [
    "a5 beetle (2012-2019)",
    "bay-window bus",
    "bay-window camper",
    "bay-window pickup",
    "beetle",
    "beetle (1958-1979)",
    "beetle (1980-2003)",
    "beetle (oval window)",
    "beetle (split window)",
    "bus",
    "caddy & rabbit pickup",
    "corrado",
    "era1950s",
    "era1970s",
    "eurovan",
    "golf & rabbit",
    "golf r",
    "golf/rabbit cabriolet",
    "gti mk1",
    "gti mk2",
    "gti mk3",
    "gti mk4",
    "gti mk5",
    "gti mk6",
    "gti mk7",
    "gti mk8",
    "jetta",
    "karmann ghia",
    "new beetle (1998-2010)",
    "parts and automobilia",
    "passat",
    "phaeton",
    "r32",
    "rail & dune buggy",
    "scirocco",
    "split-window bus",
    "split-window camper",
    "split-window pickup",
    "super beetle",
    "thing",
    "touareg",
    "type 3",
    "vanagon",
  ],
  volvo: [
    "140",
    "1800es",
    "240",
    "700-series",
    "850",
    "900-series",
    "amazon",
    "c30",
    "c70",
    "era2010s",
    "era2020s",
    "p1800/1800",
    "pv444 and pv544",
    "s60r",
    "v70",
    "v70r",
    "xc70",
  ],
  westfield: ["era1970s", "era1980s", "era2000s"],
  white: ["era1910s", "era1920s", "era1940s", "era1960s"],
  willys: [
    "chevrolet",
    "cj-2a",
    "cj-3a",
    "cj-3b",
    "jeep wagon",
    "jeepster",
    "m38",
    "mb",
    "parts and automobilia",
    "pickup",
  ],
  winnebago: ["era1960s", "era1970s", "era1980s"],
  woodill: ["era1950s"],
  yamaha: [
    "atv",
    "enduro",
    "era1960s",
    "era1970s",
    "era1980s",
    "era1990s",
    "era2000s",
    "era2010s",
    "era2020s",
    "parts and automobilia",
    "rd",
    "rz",
    "v-max",
    "xs",
    "yz",
    "yzf",
  ],
  yugo: ["yugo gv", "yugo gv plus", "yugo gvl"],
  zastava: ["era1980s"],
  "zeal motor": ["era2020s"],
  zenos: ["era2010s"],
  zimmer: ["era1980s"],
  zundapp: ["era1930s", "era1940s", "era1950s", "era1960s"],
};
